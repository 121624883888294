import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { Link as MuiLink } from '@mui/material';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import {graphql, navigate } from 'gatsby';
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import { auth } from '../../firebase-info';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import theme from '../styles/theme';
import "../styles/index.css"
import BeInput from '../components/be-input';
import BeAlert from '../components/be-alert';
import { 
  Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
  TextField,
} from '@mui/material';
import { onAuthStateChanged } from "firebase/auth";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <MuiLink color="inherit" href="">
        <Trans>Behavioral Experiments</Trans>
      </MuiLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const IndexPage = () => {
  const { t } = useTranslation();
  const [alertBox, setAlertBox] = React.useState(null);
  const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};
  const [values, setValues] = React.useState({
		email: '',
		password: '',
		isLogged: false,
		loading: {
			user: true,
		}
	});
  const [open, setOpen] = React.useState(false);

  const performLogin = async (event) => {
		try {
			await signInWithEmailAndPassword(auth, values.email, values.password);
			navigate('/dashboard/');
		} catch (error) {
			setAlertBox({
				severity: 'error',
				message: t('User or password are incorrect, please, try again')
			})
	  
			setTimeout(function () { setAlertBox(null) }, 3500);
		}
	};

  async function checkLogin() {
    onAuthStateChanged(auth, async (loggedUser) => {
      if(loggedUser) {
        navigate("/dashboard/");

        return;
      }
		});
	};

  const handleRecovery = () => {
		sendPasswordResetEmail(values.emailRecovery)
			.then((ok) => {
				setAlertBox({
					severity: 'success',
					message: 'Hemos enviado un correo para reestablecer tu contraseña.'
				  })
			})
			.catch((error) => {
				setAlertBox({
					severity: 'success',
					message: 'Hemos enviado un correo para reestablecer tu contraseña.'
				  })
			})
		setOpen(false)
		setValues({ ...values, 'emailRecovery': '' });
		setTimeout(function () { setAlertBox(null) }, 3500);
	}

  const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		values.emailRecovery = '';
		setOpen(false);
	};
  
	React.useEffect(() => {
	  checkLogin();
	}, []);

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={handleClose}>
				<DialogTitle><Trans>Recover password</Trans></DialogTitle>
				<DialogContent>
				<DialogContentText>
          <Trans>Write the email you used to register and we will send you an email with instructions to recover your password</Trans>
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="emailRecovery"
					value={values.emailRecovery}
					onChange={handleChange('emailRecovery')}
					label="Email"
					type="email"
					fullWidth
					variant="standard"
				/>
				</DialogContent>
				<DialogActions>
					<Button variant='outlined' color='secondary' onClick={handleClose}><Trans>Cancel</Trans></Button>
					<Button variant='contained' color='primary' onClick={handleRecovery}><Trans>Recover password</Trans></Button>
				</DialogActions>
			</Dialog>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        {
          alertBox ? (
            <BeAlert
              severity={alertBox.severity}
              message={alertBox.message}
            />
          ) : null
        }
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          component={Paper}
          elevation={6}
          square
        >
         <Box
            sx={{
              backgroundImage: 'url(https://www.google.com/url?sa=i&url=https%3A%2F%2Fpixabay.com%2Fimages%2Fsearch%2Frandom%2F&psig=AOvVaw2686_pvnHk1ZGiUkQC0kth&ust=1718097826253000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCLjD26fb0IYDFQAAAAAdAAAAABAE)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100vh'
            }}
          >
            <Box
              sx={{
                py: 4,
                px: 4,
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                alignItems: 'center',
              }}
            >
              <Paper
                elevation={4}
                sx={{
                  py: 2,
                  px: 4
                }}
              >
                <Typography component="h2" variant="h5" sx={{pb: 2}}>
                  <Trans>What's Behavioral Experiments? What's our goal?</Trans>
                </Typography>
                <Typography sx={{pb: 1}}>
                  <Trans>Behavioral Experiments is a platform where researchers can conduct experiments with economic and behavioral games already programmed and ready for analysis.</Trans>
                </Typography>
                <Typography color="text.secondary" sx={{pb: 1}}>
                  <Trans>Researchers can easily design the experiment setup and even modify the parameters of several games to meet their goals.</Trans>
                </Typography>
                <Typography color="text.secondary">
                  <Trans>Behavioral Experiments goal is to provide a space where researches can run their experiments effortless and fast. We hope to bring an excellent tool to foster new experiments, removing logistic barriers such hard programming or expensive infrastructure. Besides, we wish that Behavioral Experiments becomes a research platform where studies can be shared and replicated with ease.</Trans>
                </Typography>
              </Paper>
              <Paper
                elevation={4}
                sx={{
                  my: 2,
                  py: 2,
                  px: 4
                }}
              >
                <Typography component="h2" variant="h5" sx={{pb: 2}}>
                  <Trans>Who can use Behavioral Experiments?</Trans>
                </Typography>
                <Typography sx={{pb: 1}}>
                  <Trans>Both public and private research centers can use the platform for their research objectives.</Trans>
                </Typography>
                <Typography color="text.secondary">
                  <Trans>Behavioral Experiments is suitable for everyone that wants to run an experiment online, saving time and effort, gaining security and reliable data.</Trans>
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <ul>
                      <li>
                        <Typography color="text.secondary">
                          <Trans>Use economic and behavioral games</Trans>
                        </Typography>
                      </li>
                      <li>
                        <Typography color="text.secondary">
                          <Trans>Export your experiment data</Trans>
                        </Typography>
                      </li>
                      <li>
                        <Typography color="text.secondary">
                          <Trans>Set games parameters (e.g., incentives)</Trans>
                        </Typography>
                      </li>
                      <li>
                        <Typography color="text.secondary">
                          <Trans>Upload custom consent agreements</Trans>
                        </Typography>
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={6}>
                  <ul>
                      <li>
                        <Typography color="text.secondary">
                          <Trans>Randomize games presentation and incentives</Trans>
                        </Typography>
                      </li>
                      <li>
                        <Typography color="text.secondary">
                          <Trans>Create custom surveys</Trans>
                        </Typography>
                      </li>
                      <li>
                        <Typography color="text.secondary">
                          <Trans>Multilanguage support</Trans>
                        </Typography>
                      </li>
                      <li>
                        <Typography color="text.secondary">
                          <Trans>Add completion codes at the end of the experiment</Trans>
                        </Typography>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              py: 8,
              backgroundColor: '#DFE8EB',
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("Sign in")}
            </Typography>
            <Box
              noValidate
              sx={{ 
                mt: 1,
              }}
            >
              <BeInput
                  label={t ("Email")}
                  value={values.email}
                  id="inputEmail"
                  isPassword={false}
                  onChange={handleChange('email')}
                />
                <BeInput
                  label={t ("Password")}
                  value={values.password}
                  id="inputPassword"
                  isPassword={true}
                  onChange={handleChange('password')}
                />
								<Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={performLogin}
                >
                  {t("Sign in")}
                </Button>
              <Grid container>
                <Grid item xs>
                  <MuiLink onClick={handleClickOpen} variant="body2">
                    {t("Forgot password?")}
                  </MuiLink>
                </Grid>
                <Grid item>
                  <Link to={'/register/'}>
                    <Trans>Don't have an account? Sign Up</Trans>
                  </Link>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 4 }} />
              <Link to={'/participant/'} style={{ textDecoration: 'none' }}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t("Enter as a participant")}
                </Button>
              </Link>
            </Box>
            <div className='placeBottom'>
              <Divider sx={{ mb: 2 }} />
              <Grid container>
                <Grid item>
                    <Typography component="h1" variant="h5"><Trans>Contact us:</Trans></Typography>
                    <Typography color="text.secondary"><Trans>You can write us in: user@behavioralexperiments.com</Trans></Typography>
                    <Typography  component="h1" variant="h5"><Trans>We are in:</Trans></Typography>
                    <Typography color="text.secondary"><Trans>Madrid, Spain</Trans></Typography>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","index",]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
